// source: transit/transit_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var types_brand_pb = require('../types/brand_pb.js');
goog.object.extend(proto, types_brand_pb);
var types_user_pb = require('../types/user_pb.js');
goog.object.extend(proto, types_user_pb);
goog.exportSymbol('proto.transit.GetAllKeywordsRequest', null, global);
goog.exportSymbol('proto.transit.GetAllKeywordsResponse', null, global);
goog.exportSymbol('proto.transit.GetFavoriteBrandRequest', null, global);
goog.exportSymbol('proto.transit.GetFavoriteBrandResponse', null, global);
goog.exportSymbol('proto.transit.InsertBrandRequest', null, global);
goog.exportSymbol('proto.transit.InsertBrandResponse', null, global);
goog.exportSymbol('proto.transit.InsertKeywordRequest', null, global);
goog.exportSymbol('proto.transit.InsertKeywordResponse', null, global);
goog.exportSymbol('proto.transit.LoginStatusRequest', null, global);
goog.exportSymbol('proto.transit.LoginStatusResponse', null, global);
goog.exportSymbol('proto.transit.SearchBrandRequest', null, global);
goog.exportSymbol('proto.transit.SearchBrandResponse', null, global);
goog.exportSymbol('proto.transit.SearchBrandsForKeywordsRequest', null, global);
goog.exportSymbol('proto.transit.SearchBrandsForKeywordsResponse', null, global);
goog.exportSymbol('proto.transit.SearchKeywordsRequest', null, global);
goog.exportSymbol('proto.transit.SearchKeywordsResponse', null, global);
goog.exportSymbol('proto.transit.SetFavoriteBrandRequest', null, global);
goog.exportSymbol('proto.transit.SetFavoriteBrandResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transit.SearchBrandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.transit.SearchBrandRequest.repeatedFields_, null);
};
goog.inherits(proto.transit.SearchBrandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transit.SearchBrandRequest.displayName = 'proto.transit.SearchBrandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transit.SearchBrandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.transit.SearchBrandResponse.repeatedFields_, null);
};
goog.inherits(proto.transit.SearchBrandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transit.SearchBrandResponse.displayName = 'proto.transit.SearchBrandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transit.SearchKeywordsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.transit.SearchKeywordsRequest.repeatedFields_, null);
};
goog.inherits(proto.transit.SearchKeywordsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transit.SearchKeywordsRequest.displayName = 'proto.transit.SearchKeywordsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transit.SearchKeywordsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.transit.SearchKeywordsResponse.repeatedFields_, null);
};
goog.inherits(proto.transit.SearchKeywordsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transit.SearchKeywordsResponse.displayName = 'proto.transit.SearchKeywordsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transit.GetAllKeywordsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transit.GetAllKeywordsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transit.GetAllKeywordsRequest.displayName = 'proto.transit.GetAllKeywordsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transit.GetAllKeywordsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.transit.GetAllKeywordsResponse.repeatedFields_, null);
};
goog.inherits(proto.transit.GetAllKeywordsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transit.GetAllKeywordsResponse.displayName = 'proto.transit.GetAllKeywordsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transit.InsertKeywordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transit.InsertKeywordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transit.InsertKeywordRequest.displayName = 'proto.transit.InsertKeywordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transit.InsertKeywordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transit.InsertKeywordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transit.InsertKeywordResponse.displayName = 'proto.transit.InsertKeywordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transit.InsertBrandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transit.InsertBrandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transit.InsertBrandRequest.displayName = 'proto.transit.InsertBrandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transit.InsertBrandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transit.InsertBrandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transit.InsertBrandResponse.displayName = 'proto.transit.InsertBrandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transit.SearchBrandsForKeywordsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transit.SearchBrandsForKeywordsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transit.SearchBrandsForKeywordsRequest.displayName = 'proto.transit.SearchBrandsForKeywordsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transit.SearchBrandsForKeywordsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.transit.SearchBrandsForKeywordsResponse.repeatedFields_, null);
};
goog.inherits(proto.transit.SearchBrandsForKeywordsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transit.SearchBrandsForKeywordsResponse.displayName = 'proto.transit.SearchBrandsForKeywordsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transit.LoginStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transit.LoginStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transit.LoginStatusRequest.displayName = 'proto.transit.LoginStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transit.LoginStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transit.LoginStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transit.LoginStatusResponse.displayName = 'proto.transit.LoginStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transit.SetFavoriteBrandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transit.SetFavoriteBrandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transit.SetFavoriteBrandRequest.displayName = 'proto.transit.SetFavoriteBrandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transit.SetFavoriteBrandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transit.SetFavoriteBrandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transit.SetFavoriteBrandResponse.displayName = 'proto.transit.SetFavoriteBrandResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transit.GetFavoriteBrandRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transit.GetFavoriteBrandRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transit.GetFavoriteBrandRequest.displayName = 'proto.transit.GetFavoriteBrandRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transit.GetFavoriteBrandResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transit.GetFavoriteBrandResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transit.GetFavoriteBrandResponse.displayName = 'proto.transit.GetFavoriteBrandResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.transit.SearchBrandRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transit.SearchBrandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transit.SearchBrandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transit.SearchBrandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.SearchBrandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reqId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    keywordsList: jspb.Message.toObjectList(msg.getKeywordsList(),
    types_brand_pb.Keyword.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transit.SearchBrandRequest}
 */
proto.transit.SearchBrandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transit.SearchBrandRequest;
  return proto.transit.SearchBrandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transit.SearchBrandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transit.SearchBrandRequest}
 */
proto.transit.SearchBrandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setReqId(value);
      break;
    case 2:
      var value = new types_brand_pb.Keyword;
      reader.readMessage(value,types_brand_pb.Keyword.deserializeBinaryFromReader);
      msg.addKeywords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transit.SearchBrandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transit.SearchBrandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transit.SearchBrandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.SearchBrandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReqId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      types_brand_pb.Keyword.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 req_id = 1;
 * @return {number}
 */
proto.transit.SearchBrandRequest.prototype.getReqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transit.SearchBrandRequest} returns this
 */
proto.transit.SearchBrandRequest.prototype.setReqId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated types.Keyword keywords = 2;
 * @return {!Array<!proto.types.Keyword>}
 */
proto.transit.SearchBrandRequest.prototype.getKeywordsList = function() {
  return /** @type{!Array<!proto.types.Keyword>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_brand_pb.Keyword, 2));
};


/**
 * @param {!Array<!proto.types.Keyword>} value
 * @return {!proto.transit.SearchBrandRequest} returns this
*/
proto.transit.SearchBrandRequest.prototype.setKeywordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.types.Keyword=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Keyword}
 */
proto.transit.SearchBrandRequest.prototype.addKeywords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.types.Keyword, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.transit.SearchBrandRequest} returns this
 */
proto.transit.SearchBrandRequest.prototype.clearKeywordsList = function() {
  return this.setKeywordsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.transit.SearchBrandResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transit.SearchBrandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.transit.SearchBrandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transit.SearchBrandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.SearchBrandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    brandsList: jspb.Message.toObjectList(msg.getBrandsList(),
    types_brand_pb.Brand.toObject, includeInstance),
    respId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transit.SearchBrandResponse}
 */
proto.transit.SearchBrandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transit.SearchBrandResponse;
  return proto.transit.SearchBrandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transit.SearchBrandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transit.SearchBrandResponse}
 */
proto.transit.SearchBrandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new types_brand_pb.Brand;
      reader.readMessage(value,types_brand_pb.Brand.deserializeBinaryFromReader);
      msg.addBrands(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRespId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transit.SearchBrandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transit.SearchBrandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transit.SearchBrandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.SearchBrandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBrandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      types_brand_pb.Brand.serializeBinaryToWriter
    );
  }
  f = message.getRespId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * repeated types.Brand brands = 1;
 * @return {!Array<!proto.types.Brand>}
 */
proto.transit.SearchBrandResponse.prototype.getBrandsList = function() {
  return /** @type{!Array<!proto.types.Brand>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_brand_pb.Brand, 1));
};


/**
 * @param {!Array<!proto.types.Brand>} value
 * @return {!proto.transit.SearchBrandResponse} returns this
*/
proto.transit.SearchBrandResponse.prototype.setBrandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.types.Brand=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Brand}
 */
proto.transit.SearchBrandResponse.prototype.addBrands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.types.Brand, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.transit.SearchBrandResponse} returns this
 */
proto.transit.SearchBrandResponse.prototype.clearBrandsList = function() {
  return this.setBrandsList([]);
};


/**
 * optional uint64 resp_id = 2;
 * @return {number}
 */
proto.transit.SearchBrandResponse.prototype.getRespId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.transit.SearchBrandResponse} returns this
 */
proto.transit.SearchBrandResponse.prototype.setRespId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.transit.SearchKeywordsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transit.SearchKeywordsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transit.SearchKeywordsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transit.SearchKeywordsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.SearchKeywordsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reqId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    keywordsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transit.SearchKeywordsRequest}
 */
proto.transit.SearchKeywordsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transit.SearchKeywordsRequest;
  return proto.transit.SearchKeywordsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transit.SearchKeywordsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transit.SearchKeywordsRequest}
 */
proto.transit.SearchKeywordsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setReqId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addKeywords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transit.SearchKeywordsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transit.SearchKeywordsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transit.SearchKeywordsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.SearchKeywordsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReqId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional uint64 req_id = 1;
 * @return {number}
 */
proto.transit.SearchKeywordsRequest.prototype.getReqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transit.SearchKeywordsRequest} returns this
 */
proto.transit.SearchKeywordsRequest.prototype.setReqId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string keywords = 2;
 * @return {!Array<string>}
 */
proto.transit.SearchKeywordsRequest.prototype.getKeywordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.transit.SearchKeywordsRequest} returns this
 */
proto.transit.SearchKeywordsRequest.prototype.setKeywordsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.transit.SearchKeywordsRequest} returns this
 */
proto.transit.SearchKeywordsRequest.prototype.addKeywords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.transit.SearchKeywordsRequest} returns this
 */
proto.transit.SearchKeywordsRequest.prototype.clearKeywordsList = function() {
  return this.setKeywordsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.transit.SearchKeywordsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transit.SearchKeywordsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.transit.SearchKeywordsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transit.SearchKeywordsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.SearchKeywordsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    respId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    keywordsList: jspb.Message.toObjectList(msg.getKeywordsList(),
    types_brand_pb.Keyword.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transit.SearchKeywordsResponse}
 */
proto.transit.SearchKeywordsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transit.SearchKeywordsResponse;
  return proto.transit.SearchKeywordsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transit.SearchKeywordsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transit.SearchKeywordsResponse}
 */
proto.transit.SearchKeywordsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRespId(value);
      break;
    case 2:
      var value = new types_brand_pb.Keyword;
      reader.readMessage(value,types_brand_pb.Keyword.deserializeBinaryFromReader);
      msg.addKeywords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transit.SearchKeywordsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transit.SearchKeywordsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transit.SearchKeywordsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.SearchKeywordsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRespId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      types_brand_pb.Keyword.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 resp_id = 1;
 * @return {number}
 */
proto.transit.SearchKeywordsResponse.prototype.getRespId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transit.SearchKeywordsResponse} returns this
 */
proto.transit.SearchKeywordsResponse.prototype.setRespId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated types.Keyword keywords = 2;
 * @return {!Array<!proto.types.Keyword>}
 */
proto.transit.SearchKeywordsResponse.prototype.getKeywordsList = function() {
  return /** @type{!Array<!proto.types.Keyword>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_brand_pb.Keyword, 2));
};


/**
 * @param {!Array<!proto.types.Keyword>} value
 * @return {!proto.transit.SearchKeywordsResponse} returns this
*/
proto.transit.SearchKeywordsResponse.prototype.setKeywordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.types.Keyword=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Keyword}
 */
proto.transit.SearchKeywordsResponse.prototype.addKeywords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.types.Keyword, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.transit.SearchKeywordsResponse} returns this
 */
proto.transit.SearchKeywordsResponse.prototype.clearKeywordsList = function() {
  return this.setKeywordsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transit.GetAllKeywordsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transit.GetAllKeywordsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transit.GetAllKeywordsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.GetAllKeywordsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reqId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transit.GetAllKeywordsRequest}
 */
proto.transit.GetAllKeywordsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transit.GetAllKeywordsRequest;
  return proto.transit.GetAllKeywordsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transit.GetAllKeywordsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transit.GetAllKeywordsRequest}
 */
proto.transit.GetAllKeywordsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setReqId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transit.GetAllKeywordsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transit.GetAllKeywordsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transit.GetAllKeywordsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.GetAllKeywordsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReqId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 req_id = 1;
 * @return {number}
 */
proto.transit.GetAllKeywordsRequest.prototype.getReqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transit.GetAllKeywordsRequest} returns this
 */
proto.transit.GetAllKeywordsRequest.prototype.setReqId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.transit.GetAllKeywordsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transit.GetAllKeywordsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.transit.GetAllKeywordsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transit.GetAllKeywordsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.GetAllKeywordsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    respId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    keywordsList: jspb.Message.toObjectList(msg.getKeywordsList(),
    types_brand_pb.Keyword.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transit.GetAllKeywordsResponse}
 */
proto.transit.GetAllKeywordsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transit.GetAllKeywordsResponse;
  return proto.transit.GetAllKeywordsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transit.GetAllKeywordsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transit.GetAllKeywordsResponse}
 */
proto.transit.GetAllKeywordsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRespId(value);
      break;
    case 2:
      var value = new types_brand_pb.Keyword;
      reader.readMessage(value,types_brand_pb.Keyword.deserializeBinaryFromReader);
      msg.addKeywords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transit.GetAllKeywordsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transit.GetAllKeywordsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transit.GetAllKeywordsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.GetAllKeywordsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRespId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      types_brand_pb.Keyword.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 resp_id = 1;
 * @return {number}
 */
proto.transit.GetAllKeywordsResponse.prototype.getRespId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transit.GetAllKeywordsResponse} returns this
 */
proto.transit.GetAllKeywordsResponse.prototype.setRespId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated types.Keyword keywords = 2;
 * @return {!Array<!proto.types.Keyword>}
 */
proto.transit.GetAllKeywordsResponse.prototype.getKeywordsList = function() {
  return /** @type{!Array<!proto.types.Keyword>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_brand_pb.Keyword, 2));
};


/**
 * @param {!Array<!proto.types.Keyword>} value
 * @return {!proto.transit.GetAllKeywordsResponse} returns this
*/
proto.transit.GetAllKeywordsResponse.prototype.setKeywordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.types.Keyword=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Keyword}
 */
proto.transit.GetAllKeywordsResponse.prototype.addKeywords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.types.Keyword, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.transit.GetAllKeywordsResponse} returns this
 */
proto.transit.GetAllKeywordsResponse.prototype.clearKeywordsList = function() {
  return this.setKeywordsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transit.InsertKeywordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transit.InsertKeywordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transit.InsertKeywordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.InsertKeywordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reqId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    keyword: (f = msg.getKeyword()) && types_brand_pb.Keyword.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transit.InsertKeywordRequest}
 */
proto.transit.InsertKeywordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transit.InsertKeywordRequest;
  return proto.transit.InsertKeywordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transit.InsertKeywordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transit.InsertKeywordRequest}
 */
proto.transit.InsertKeywordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setReqId(value);
      break;
    case 2:
      var value = new types_brand_pb.Keyword;
      reader.readMessage(value,types_brand_pb.Keyword.deserializeBinaryFromReader);
      msg.setKeyword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transit.InsertKeywordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transit.InsertKeywordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transit.InsertKeywordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.InsertKeywordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReqId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getKeyword();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      types_brand_pb.Keyword.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 req_id = 1;
 * @return {number}
 */
proto.transit.InsertKeywordRequest.prototype.getReqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transit.InsertKeywordRequest} returns this
 */
proto.transit.InsertKeywordRequest.prototype.setReqId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional types.Keyword keyword = 2;
 * @return {?proto.types.Keyword}
 */
proto.transit.InsertKeywordRequest.prototype.getKeyword = function() {
  return /** @type{?proto.types.Keyword} */ (
    jspb.Message.getWrapperField(this, types_brand_pb.Keyword, 2));
};


/**
 * @param {?proto.types.Keyword|undefined} value
 * @return {!proto.transit.InsertKeywordRequest} returns this
*/
proto.transit.InsertKeywordRequest.prototype.setKeyword = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.transit.InsertKeywordRequest} returns this
 */
proto.transit.InsertKeywordRequest.prototype.clearKeyword = function() {
  return this.setKeyword(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transit.InsertKeywordRequest.prototype.hasKeyword = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transit.InsertKeywordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.transit.InsertKeywordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transit.InsertKeywordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.InsertKeywordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    respId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transit.InsertKeywordResponse}
 */
proto.transit.InsertKeywordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transit.InsertKeywordResponse;
  return proto.transit.InsertKeywordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transit.InsertKeywordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transit.InsertKeywordResponse}
 */
proto.transit.InsertKeywordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRespId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transit.InsertKeywordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transit.InsertKeywordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transit.InsertKeywordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.InsertKeywordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRespId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 resp_id = 1;
 * @return {number}
 */
proto.transit.InsertKeywordResponse.prototype.getRespId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transit.InsertKeywordResponse} returns this
 */
proto.transit.InsertKeywordResponse.prototype.setRespId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transit.InsertBrandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transit.InsertBrandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transit.InsertBrandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.InsertBrandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reqId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    brand: (f = msg.getBrand()) && types_brand_pb.Brand.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transit.InsertBrandRequest}
 */
proto.transit.InsertBrandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transit.InsertBrandRequest;
  return proto.transit.InsertBrandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transit.InsertBrandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transit.InsertBrandRequest}
 */
proto.transit.InsertBrandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setReqId(value);
      break;
    case 2:
      var value = new types_brand_pb.Brand;
      reader.readMessage(value,types_brand_pb.Brand.deserializeBinaryFromReader);
      msg.setBrand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transit.InsertBrandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transit.InsertBrandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transit.InsertBrandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.InsertBrandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReqId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getBrand();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      types_brand_pb.Brand.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 req_id = 1;
 * @return {number}
 */
proto.transit.InsertBrandRequest.prototype.getReqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transit.InsertBrandRequest} returns this
 */
proto.transit.InsertBrandRequest.prototype.setReqId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional types.Brand brand = 2;
 * @return {?proto.types.Brand}
 */
proto.transit.InsertBrandRequest.prototype.getBrand = function() {
  return /** @type{?proto.types.Brand} */ (
    jspb.Message.getWrapperField(this, types_brand_pb.Brand, 2));
};


/**
 * @param {?proto.types.Brand|undefined} value
 * @return {!proto.transit.InsertBrandRequest} returns this
*/
proto.transit.InsertBrandRequest.prototype.setBrand = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.transit.InsertBrandRequest} returns this
 */
proto.transit.InsertBrandRequest.prototype.clearBrand = function() {
  return this.setBrand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transit.InsertBrandRequest.prototype.hasBrand = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transit.InsertBrandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.transit.InsertBrandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transit.InsertBrandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.InsertBrandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    respId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transit.InsertBrandResponse}
 */
proto.transit.InsertBrandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transit.InsertBrandResponse;
  return proto.transit.InsertBrandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transit.InsertBrandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transit.InsertBrandResponse}
 */
proto.transit.InsertBrandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRespId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transit.InsertBrandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transit.InsertBrandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transit.InsertBrandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.InsertBrandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRespId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 resp_id = 1;
 * @return {number}
 */
proto.transit.InsertBrandResponse.prototype.getRespId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transit.InsertBrandResponse} returns this
 */
proto.transit.InsertBrandResponse.prototype.setRespId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transit.SearchBrandsForKeywordsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transit.SearchBrandsForKeywordsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transit.SearchBrandsForKeywordsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.SearchBrandsForKeywordsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reqId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    keyword: (f = msg.getKeyword()) && types_brand_pb.Keyword.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transit.SearchBrandsForKeywordsRequest}
 */
proto.transit.SearchBrandsForKeywordsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transit.SearchBrandsForKeywordsRequest;
  return proto.transit.SearchBrandsForKeywordsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transit.SearchBrandsForKeywordsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transit.SearchBrandsForKeywordsRequest}
 */
proto.transit.SearchBrandsForKeywordsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setReqId(value);
      break;
    case 2:
      var value = new types_brand_pb.Keyword;
      reader.readMessage(value,types_brand_pb.Keyword.deserializeBinaryFromReader);
      msg.setKeyword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transit.SearchBrandsForKeywordsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transit.SearchBrandsForKeywordsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transit.SearchBrandsForKeywordsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.SearchBrandsForKeywordsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReqId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getKeyword();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      types_brand_pb.Keyword.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 req_id = 1;
 * @return {number}
 */
proto.transit.SearchBrandsForKeywordsRequest.prototype.getReqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transit.SearchBrandsForKeywordsRequest} returns this
 */
proto.transit.SearchBrandsForKeywordsRequest.prototype.setReqId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional types.Keyword Keyword = 2;
 * @return {?proto.types.Keyword}
 */
proto.transit.SearchBrandsForKeywordsRequest.prototype.getKeyword = function() {
  return /** @type{?proto.types.Keyword} */ (
    jspb.Message.getWrapperField(this, types_brand_pb.Keyword, 2));
};


/**
 * @param {?proto.types.Keyword|undefined} value
 * @return {!proto.transit.SearchBrandsForKeywordsRequest} returns this
*/
proto.transit.SearchBrandsForKeywordsRequest.prototype.setKeyword = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.transit.SearchBrandsForKeywordsRequest} returns this
 */
proto.transit.SearchBrandsForKeywordsRequest.prototype.clearKeyword = function() {
  return this.setKeyword(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transit.SearchBrandsForKeywordsRequest.prototype.hasKeyword = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.transit.SearchBrandsForKeywordsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transit.SearchBrandsForKeywordsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.transit.SearchBrandsForKeywordsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transit.SearchBrandsForKeywordsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.SearchBrandsForKeywordsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    respId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    brandsList: jspb.Message.toObjectList(msg.getBrandsList(),
    types_brand_pb.Brand.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transit.SearchBrandsForKeywordsResponse}
 */
proto.transit.SearchBrandsForKeywordsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transit.SearchBrandsForKeywordsResponse;
  return proto.transit.SearchBrandsForKeywordsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transit.SearchBrandsForKeywordsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transit.SearchBrandsForKeywordsResponse}
 */
proto.transit.SearchBrandsForKeywordsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRespId(value);
      break;
    case 2:
      var value = new types_brand_pb.Brand;
      reader.readMessage(value,types_brand_pb.Brand.deserializeBinaryFromReader);
      msg.addBrands(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transit.SearchBrandsForKeywordsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transit.SearchBrandsForKeywordsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transit.SearchBrandsForKeywordsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.SearchBrandsForKeywordsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRespId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getBrandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      types_brand_pb.Brand.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 resp_id = 1;
 * @return {number}
 */
proto.transit.SearchBrandsForKeywordsResponse.prototype.getRespId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transit.SearchBrandsForKeywordsResponse} returns this
 */
proto.transit.SearchBrandsForKeywordsResponse.prototype.setRespId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated types.Brand brands = 2;
 * @return {!Array<!proto.types.Brand>}
 */
proto.transit.SearchBrandsForKeywordsResponse.prototype.getBrandsList = function() {
  return /** @type{!Array<!proto.types.Brand>} */ (
    jspb.Message.getRepeatedWrapperField(this, types_brand_pb.Brand, 2));
};


/**
 * @param {!Array<!proto.types.Brand>} value
 * @return {!proto.transit.SearchBrandsForKeywordsResponse} returns this
*/
proto.transit.SearchBrandsForKeywordsResponse.prototype.setBrandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.types.Brand=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Brand}
 */
proto.transit.SearchBrandsForKeywordsResponse.prototype.addBrands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.types.Brand, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.transit.SearchBrandsForKeywordsResponse} returns this
 */
proto.transit.SearchBrandsForKeywordsResponse.prototype.clearBrandsList = function() {
  return this.setBrandsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transit.LoginStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transit.LoginStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transit.LoginStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.LoginStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reqId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transit.LoginStatusRequest}
 */
proto.transit.LoginStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transit.LoginStatusRequest;
  return proto.transit.LoginStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transit.LoginStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transit.LoginStatusRequest}
 */
proto.transit.LoginStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setReqId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transit.LoginStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transit.LoginStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transit.LoginStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.LoginStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReqId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 req_id = 1;
 * @return {number}
 */
proto.transit.LoginStatusRequest.prototype.getReqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transit.LoginStatusRequest} returns this
 */
proto.transit.LoginStatusRequest.prototype.setReqId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transit.LoginStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.transit.LoginStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transit.LoginStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.LoginStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    respId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    loggedIn: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    user: (f = msg.getUser()) && types_user_pb.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transit.LoginStatusResponse}
 */
proto.transit.LoginStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transit.LoginStatusResponse;
  return proto.transit.LoginStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transit.LoginStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transit.LoginStatusResponse}
 */
proto.transit.LoginStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRespId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLoggedIn(value);
      break;
    case 3:
      var value = new types_user_pb.User;
      reader.readMessage(value,types_user_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transit.LoginStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transit.LoginStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transit.LoginStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.LoginStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRespId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getLoggedIn();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      types_user_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 resp_id = 1;
 * @return {number}
 */
proto.transit.LoginStatusResponse.prototype.getRespId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transit.LoginStatusResponse} returns this
 */
proto.transit.LoginStatusResponse.prototype.setRespId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool logged_in = 2;
 * @return {boolean}
 */
proto.transit.LoginStatusResponse.prototype.getLoggedIn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.transit.LoginStatusResponse} returns this
 */
proto.transit.LoginStatusResponse.prototype.setLoggedIn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional types.User user = 3;
 * @return {?proto.types.User}
 */
proto.transit.LoginStatusResponse.prototype.getUser = function() {
  return /** @type{?proto.types.User} */ (
    jspb.Message.getWrapperField(this, types_user_pb.User, 3));
};


/**
 * @param {?proto.types.User|undefined} value
 * @return {!proto.transit.LoginStatusResponse} returns this
*/
proto.transit.LoginStatusResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.transit.LoginStatusResponse} returns this
 */
proto.transit.LoginStatusResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.transit.LoginStatusResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transit.SetFavoriteBrandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transit.SetFavoriteBrandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transit.SetFavoriteBrandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.SetFavoriteBrandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reqId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    targetState: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transit.SetFavoriteBrandRequest}
 */
proto.transit.SetFavoriteBrandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transit.SetFavoriteBrandRequest;
  return proto.transit.SetFavoriteBrandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transit.SetFavoriteBrandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transit.SetFavoriteBrandRequest}
 */
proto.transit.SetFavoriteBrandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setReqId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTargetState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transit.SetFavoriteBrandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transit.SetFavoriteBrandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transit.SetFavoriteBrandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.SetFavoriteBrandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReqId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getBrandId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTargetState();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional uint64 req_id = 1;
 * @return {number}
 */
proto.transit.SetFavoriteBrandRequest.prototype.getReqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transit.SetFavoriteBrandRequest} returns this
 */
proto.transit.SetFavoriteBrandRequest.prototype.setReqId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string brand_id = 2;
 * @return {string}
 */
proto.transit.SetFavoriteBrandRequest.prototype.getBrandId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.transit.SetFavoriteBrandRequest} returns this
 */
proto.transit.SetFavoriteBrandRequest.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool target_state = 3;
 * @return {boolean}
 */
proto.transit.SetFavoriteBrandRequest.prototype.getTargetState = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.transit.SetFavoriteBrandRequest} returns this
 */
proto.transit.SetFavoriteBrandRequest.prototype.setTargetState = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transit.SetFavoriteBrandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.transit.SetFavoriteBrandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transit.SetFavoriteBrandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.SetFavoriteBrandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    respId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currentState: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transit.SetFavoriteBrandResponse}
 */
proto.transit.SetFavoriteBrandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transit.SetFavoriteBrandResponse;
  return proto.transit.SetFavoriteBrandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transit.SetFavoriteBrandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transit.SetFavoriteBrandResponse}
 */
proto.transit.SetFavoriteBrandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRespId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCurrentState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transit.SetFavoriteBrandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transit.SetFavoriteBrandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transit.SetFavoriteBrandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.SetFavoriteBrandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRespId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getBrandId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrentState();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional uint64 resp_id = 1;
 * @return {number}
 */
proto.transit.SetFavoriteBrandResponse.prototype.getRespId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transit.SetFavoriteBrandResponse} returns this
 */
proto.transit.SetFavoriteBrandResponse.prototype.setRespId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string brand_id = 2;
 * @return {string}
 */
proto.transit.SetFavoriteBrandResponse.prototype.getBrandId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.transit.SetFavoriteBrandResponse} returns this
 */
proto.transit.SetFavoriteBrandResponse.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool current_state = 3;
 * @return {boolean}
 */
proto.transit.SetFavoriteBrandResponse.prototype.getCurrentState = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.transit.SetFavoriteBrandResponse} returns this
 */
proto.transit.SetFavoriteBrandResponse.prototype.setCurrentState = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transit.GetFavoriteBrandRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transit.GetFavoriteBrandRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transit.GetFavoriteBrandRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.GetFavoriteBrandRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reqId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    targetState: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transit.GetFavoriteBrandRequest}
 */
proto.transit.GetFavoriteBrandRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transit.GetFavoriteBrandRequest;
  return proto.transit.GetFavoriteBrandRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transit.GetFavoriteBrandRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transit.GetFavoriteBrandRequest}
 */
proto.transit.GetFavoriteBrandRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setReqId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTargetState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transit.GetFavoriteBrandRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transit.GetFavoriteBrandRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transit.GetFavoriteBrandRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.GetFavoriteBrandRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReqId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getBrandId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTargetState();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional uint64 req_id = 1;
 * @return {number}
 */
proto.transit.GetFavoriteBrandRequest.prototype.getReqId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transit.GetFavoriteBrandRequest} returns this
 */
proto.transit.GetFavoriteBrandRequest.prototype.setReqId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string brand_id = 2;
 * @return {string}
 */
proto.transit.GetFavoriteBrandRequest.prototype.getBrandId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.transit.GetFavoriteBrandRequest} returns this
 */
proto.transit.GetFavoriteBrandRequest.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool target_state = 3;
 * @return {boolean}
 */
proto.transit.GetFavoriteBrandRequest.prototype.getTargetState = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.transit.GetFavoriteBrandRequest} returns this
 */
proto.transit.GetFavoriteBrandRequest.prototype.setTargetState = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transit.GetFavoriteBrandResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.transit.GetFavoriteBrandResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transit.GetFavoriteBrandResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.GetFavoriteBrandResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    respId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    brandId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currentState: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transit.GetFavoriteBrandResponse}
 */
proto.transit.GetFavoriteBrandResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transit.GetFavoriteBrandResponse;
  return proto.transit.GetFavoriteBrandResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transit.GetFavoriteBrandResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transit.GetFavoriteBrandResponse}
 */
proto.transit.GetFavoriteBrandResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRespId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCurrentState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transit.GetFavoriteBrandResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transit.GetFavoriteBrandResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transit.GetFavoriteBrandResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transit.GetFavoriteBrandResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRespId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getBrandId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrentState();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional uint64 resp_id = 1;
 * @return {number}
 */
proto.transit.GetFavoriteBrandResponse.prototype.getRespId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transit.GetFavoriteBrandResponse} returns this
 */
proto.transit.GetFavoriteBrandResponse.prototype.setRespId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string brand_id = 2;
 * @return {string}
 */
proto.transit.GetFavoriteBrandResponse.prototype.getBrandId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.transit.GetFavoriteBrandResponse} returns this
 */
proto.transit.GetFavoriteBrandResponse.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool current_state = 3;
 * @return {boolean}
 */
proto.transit.GetFavoriteBrandResponse.prototype.getCurrentState = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.transit.GetFavoriteBrandResponse} returns this
 */
proto.transit.GetFavoriteBrandResponse.prototype.setCurrentState = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


goog.object.extend(exports, proto.transit);
