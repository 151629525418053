/**
 * @fileoverview gRPC-Web generated client stub for transit
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as transit_transit_service_pb from '../transit/transit_service_pb';


export class TransitManagerClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoLoginStatus = new grpcWeb.AbstractClientBase.MethodInfo(
    transit_transit_service_pb.LoginStatusResponse,
    (request: transit_transit_service_pb.LoginStatusRequest) => {
      return request.serializeBinary();
    },
    transit_transit_service_pb.LoginStatusResponse.deserializeBinary
  );

  loginStatus(
    request: transit_transit_service_pb.LoginStatusRequest,
    metadata: grpcWeb.Metadata | null): Promise<transit_transit_service_pb.LoginStatusResponse>;

  loginStatus(
    request: transit_transit_service_pb.LoginStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: transit_transit_service_pb.LoginStatusResponse) => void): grpcWeb.ClientReadableStream<transit_transit_service_pb.LoginStatusResponse>;

  loginStatus(
    request: transit_transit_service_pb.LoginStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: transit_transit_service_pb.LoginStatusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transit.TransitManager/LoginStatus',
        request,
        metadata || {},
        this.methodInfoLoginStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transit.TransitManager/LoginStatus',
    request,
    metadata || {},
    this.methodInfoLoginStatus);
  }

  methodInfoSearchBrands = new grpcWeb.AbstractClientBase.MethodInfo(
    transit_transit_service_pb.SearchBrandResponse,
    (request: transit_transit_service_pb.SearchBrandRequest) => {
      return request.serializeBinary();
    },
    transit_transit_service_pb.SearchBrandResponse.deserializeBinary
  );

  searchBrands(
    request: transit_transit_service_pb.SearchBrandRequest,
    metadata: grpcWeb.Metadata | null): Promise<transit_transit_service_pb.SearchBrandResponse>;

  searchBrands(
    request: transit_transit_service_pb.SearchBrandRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: transit_transit_service_pb.SearchBrandResponse) => void): grpcWeb.ClientReadableStream<transit_transit_service_pb.SearchBrandResponse>;

  searchBrands(
    request: transit_transit_service_pb.SearchBrandRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: transit_transit_service_pb.SearchBrandResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transit.TransitManager/SearchBrands',
        request,
        metadata || {},
        this.methodInfoSearchBrands,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transit.TransitManager/SearchBrands',
    request,
    metadata || {},
    this.methodInfoSearchBrands);
  }

  methodInfoSearchKeywords = new grpcWeb.AbstractClientBase.MethodInfo(
    transit_transit_service_pb.SearchKeywordsResponse,
    (request: transit_transit_service_pb.SearchKeywordsRequest) => {
      return request.serializeBinary();
    },
    transit_transit_service_pb.SearchKeywordsResponse.deserializeBinary
  );

  searchKeywords(
    request: transit_transit_service_pb.SearchKeywordsRequest,
    metadata: grpcWeb.Metadata | null): Promise<transit_transit_service_pb.SearchKeywordsResponse>;

  searchKeywords(
    request: transit_transit_service_pb.SearchKeywordsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: transit_transit_service_pb.SearchKeywordsResponse) => void): grpcWeb.ClientReadableStream<transit_transit_service_pb.SearchKeywordsResponse>;

  searchKeywords(
    request: transit_transit_service_pb.SearchKeywordsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: transit_transit_service_pb.SearchKeywordsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transit.TransitManager/SearchKeywords',
        request,
        metadata || {},
        this.methodInfoSearchKeywords,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transit.TransitManager/SearchKeywords',
    request,
    metadata || {},
    this.methodInfoSearchKeywords);
  }

  methodInfoGetAllKeywords = new grpcWeb.AbstractClientBase.MethodInfo(
    transit_transit_service_pb.GetAllKeywordsResponse,
    (request: transit_transit_service_pb.GetAllKeywordsRequest) => {
      return request.serializeBinary();
    },
    transit_transit_service_pb.GetAllKeywordsResponse.deserializeBinary
  );

  getAllKeywords(
    request: transit_transit_service_pb.GetAllKeywordsRequest,
    metadata: grpcWeb.Metadata | null): Promise<transit_transit_service_pb.GetAllKeywordsResponse>;

  getAllKeywords(
    request: transit_transit_service_pb.GetAllKeywordsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: transit_transit_service_pb.GetAllKeywordsResponse) => void): grpcWeb.ClientReadableStream<transit_transit_service_pb.GetAllKeywordsResponse>;

  getAllKeywords(
    request: transit_transit_service_pb.GetAllKeywordsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: transit_transit_service_pb.GetAllKeywordsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transit.TransitManager/GetAllKeywords',
        request,
        metadata || {},
        this.methodInfoGetAllKeywords,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transit.TransitManager/GetAllKeywords',
    request,
    metadata || {},
    this.methodInfoGetAllKeywords);
  }

  methodInfoSetFavoriteBrand = new grpcWeb.AbstractClientBase.MethodInfo(
    transit_transit_service_pb.SetFavoriteBrandResponse,
    (request: transit_transit_service_pb.SetFavoriteBrandRequest) => {
      return request.serializeBinary();
    },
    transit_transit_service_pb.SetFavoriteBrandResponse.deserializeBinary
  );

  setFavoriteBrand(
    request: transit_transit_service_pb.SetFavoriteBrandRequest,
    metadata: grpcWeb.Metadata | null): Promise<transit_transit_service_pb.SetFavoriteBrandResponse>;

  setFavoriteBrand(
    request: transit_transit_service_pb.SetFavoriteBrandRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: transit_transit_service_pb.SetFavoriteBrandResponse) => void): grpcWeb.ClientReadableStream<transit_transit_service_pb.SetFavoriteBrandResponse>;

  setFavoriteBrand(
    request: transit_transit_service_pb.SetFavoriteBrandRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: transit_transit_service_pb.SetFavoriteBrandResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transit.TransitManager/SetFavoriteBrand',
        request,
        metadata || {},
        this.methodInfoSetFavoriteBrand,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transit.TransitManager/SetFavoriteBrand',
    request,
    metadata || {},
    this.methodInfoSetFavoriteBrand);
  }

  methodInfoGetFavoriteBrands = new grpcWeb.AbstractClientBase.MethodInfo(
    transit_transit_service_pb.SetFavoriteBrandResponse,
    (request: transit_transit_service_pb.SetFavoriteBrandRequest) => {
      return request.serializeBinary();
    },
    transit_transit_service_pb.SetFavoriteBrandResponse.deserializeBinary
  );

  getFavoriteBrands(
    request: transit_transit_service_pb.SetFavoriteBrandRequest,
    metadata: grpcWeb.Metadata | null): Promise<transit_transit_service_pb.SetFavoriteBrandResponse>;

  getFavoriteBrands(
    request: transit_transit_service_pb.SetFavoriteBrandRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: transit_transit_service_pb.SetFavoriteBrandResponse) => void): grpcWeb.ClientReadableStream<transit_transit_service_pb.SetFavoriteBrandResponse>;

  getFavoriteBrands(
    request: transit_transit_service_pb.SetFavoriteBrandRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: transit_transit_service_pb.SetFavoriteBrandResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transit.TransitManager/GetFavoriteBrands',
        request,
        metadata || {},
        this.methodInfoGetFavoriteBrands,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transit.TransitManager/GetFavoriteBrands',
    request,
    metadata || {},
    this.methodInfoGetFavoriteBrands);
  }

  methodInfoInsertKeyword = new grpcWeb.AbstractClientBase.MethodInfo(
    transit_transit_service_pb.InsertKeywordResponse,
    (request: transit_transit_service_pb.InsertKeywordRequest) => {
      return request.serializeBinary();
    },
    transit_transit_service_pb.InsertKeywordResponse.deserializeBinary
  );

  insertKeyword(
    request: transit_transit_service_pb.InsertKeywordRequest,
    metadata: grpcWeb.Metadata | null): Promise<transit_transit_service_pb.InsertKeywordResponse>;

  insertKeyword(
    request: transit_transit_service_pb.InsertKeywordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: transit_transit_service_pb.InsertKeywordResponse) => void): grpcWeb.ClientReadableStream<transit_transit_service_pb.InsertKeywordResponse>;

  insertKeyword(
    request: transit_transit_service_pb.InsertKeywordRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: transit_transit_service_pb.InsertKeywordResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transit.TransitManager/InsertKeyword',
        request,
        metadata || {},
        this.methodInfoInsertKeyword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transit.TransitManager/InsertKeyword',
    request,
    metadata || {},
    this.methodInfoInsertKeyword);
  }

  methodInfoInsertBrand = new grpcWeb.AbstractClientBase.MethodInfo(
    transit_transit_service_pb.InsertBrandResponse,
    (request: transit_transit_service_pb.InsertBrandRequest) => {
      return request.serializeBinary();
    },
    transit_transit_service_pb.InsertBrandResponse.deserializeBinary
  );

  insertBrand(
    request: transit_transit_service_pb.InsertBrandRequest,
    metadata: grpcWeb.Metadata | null): Promise<transit_transit_service_pb.InsertBrandResponse>;

  insertBrand(
    request: transit_transit_service_pb.InsertBrandRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: transit_transit_service_pb.InsertBrandResponse) => void): grpcWeb.ClientReadableStream<transit_transit_service_pb.InsertBrandResponse>;

  insertBrand(
    request: transit_transit_service_pb.InsertBrandRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: transit_transit_service_pb.InsertBrandResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transit.TransitManager/InsertBrand',
        request,
        metadata || {},
        this.methodInfoInsertBrand,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transit.TransitManager/InsertBrand',
    request,
    metadata || {},
    this.methodInfoInsertBrand);
  }

  methodInfoSearchBrandsForKeywords = new grpcWeb.AbstractClientBase.MethodInfo(
    transit_transit_service_pb.SearchBrandsForKeywordsResponse,
    (request: transit_transit_service_pb.SearchBrandsForKeywordsRequest) => {
      return request.serializeBinary();
    },
    transit_transit_service_pb.SearchBrandsForKeywordsResponse.deserializeBinary
  );

  searchBrandsForKeywords(
    request: transit_transit_service_pb.SearchBrandsForKeywordsRequest,
    metadata: grpcWeb.Metadata | null): Promise<transit_transit_service_pb.SearchBrandsForKeywordsResponse>;

  searchBrandsForKeywords(
    request: transit_transit_service_pb.SearchBrandsForKeywordsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: transit_transit_service_pb.SearchBrandsForKeywordsResponse) => void): grpcWeb.ClientReadableStream<transit_transit_service_pb.SearchBrandsForKeywordsResponse>;

  searchBrandsForKeywords(
    request: transit_transit_service_pb.SearchBrandsForKeywordsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: transit_transit_service_pb.SearchBrandsForKeywordsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/transit.TransitManager/SearchBrandsForKeywords',
        request,
        metadata || {},
        this.methodInfoSearchBrandsForKeywords,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/transit.TransitManager/SearchBrandsForKeywords',
    request,
    metadata || {},
    this.methodInfoSearchBrandsForKeywords);
  }

}

