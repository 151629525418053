import { ReactElement } from 'react';
import * as React from 'react';
import './App.css';
import TopLevelRouter from './pages/TopLevelRouter';
import {BrowserRouter as Router} from 'react-router-dom';
import { withRouter } from "react-router";

function App(): ReactElement {
  const TopLevelRouterWithRouter = withRouter(TopLevelRouter);
  return (
    <div className="App">
      <Router>
        <TopLevelRouterWithRouter></TopLevelRouterWithRouter>
      </Router>
    </div>
  );
}

export default App;
