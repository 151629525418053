import React, { ReactElement } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import { RouteComponentProps, Search } from "react-router";
import Nav from "react-bootstrap/Nav";

import SearchManager from "../components/SearchManager";
import { newTransitConnection } from "../transit/TransitHelpers";
import { LoginStatusRequest } from "../transit/transit_service_pb.js";

import "../style/FormArea.css";
import LoginPage from "./LoginPage";
import AddBrand from "./AddBrand";

const server = newTransitConnection();

interface TopLevelRouterProps {
  x?: number;
}

interface TopLevelRouterState {
  loggedin: boolean;
  counter: number;
}

export default class TopLevelRouter extends React.Component<TopLevelRouterProps & RouteComponentProps, TopLevelRouterState> {
  constructor(props: TopLevelRouterProps & RouteComponentProps) {
    super(props);
    this.incrementRequestCounter = this.incrementRequestCounter.bind(this);
  }

  static defaultProps: TopLevelRouterProps = {
    x: 1,
  };

  state: Readonly<TopLevelRouterState> = {
    loggedin: false,
    counter: 0,
  };

  incrementRequestCounter(): void {
    this.setState((state) => {
      return {
        counter: state.counter + 1,
      };
    });
  }

  componentDidMount(): void {
    this.checkLoginStatus();
  }

  checkLoginStatus(): void {
    const { counter } = this.state;
    //    const query = new URLSearchParams(this.props.location.search);
    const req = new LoginStatusRequest();
    req.setReqId(counter);
    server.loginStatus(req, {}, (err, response) => {
      if (err || response === null) {
        console.log("couldn't get loginstatus", err);
        return;
      }
      this.setState({ loggedin: response.getLoggedIn(), counter: counter + 1 });
    });
  }

  render(): React.ReactNode {
    return (
      <div>
        <Router>
          <div>
            <nav>
              <Nav defaultActiveKey="/" as="ul" className="navbar navbar-expand-md navbar-light">
                <Nav.Item as="h3">Find Stuff you Love</Nav.Item>
                <Nav.Item as="li" className="ml-auto">
                  <Nav.Link as={Link} to="/add/brand">
                    Add a Brand
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="">
                  <Nav.Link as={Link} to={`/account/${this.state.loggedin ? "" : "login"}`}>
                    {this.state.loggedin ? "My Favorites" : "Login"}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </nav>

            <Switch>
              <Route path="/manage">
                <About />
                {/* <AdminManager server={server} /> */}
              </Route>
              <Route path="/add">
                <AddBrand server={server} />
              </Route>
               <Route
                path="/account/login"
                // component={() => {
                //   window.location.replace("http://localhost:8812/login");
                //   return null;
                // }}
              >
              <LoginPage />
                {/* Set the last page here, to put them back */}
              </Route>
              <Route path="/account">
                {" "}
                {/* Get the last page here, and set to={lastState} */}
                {console.log(this.props.location.search)}
                <Redirect to="/" />
              </Route>
              <Route path="/">
                <SearchManager server={server} />
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

function About() {
  return <h2>About</h2>;
}
