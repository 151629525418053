import * as React from "react";
import Button from "react-bootstrap/Button";
import Jumbotron from "react-bootstrap/Jumbotron";

import Card from "./Card";
import { TransitManagerClient } from "../transit/Transit_serviceServiceClientPb";
import { SearchBrandRequest, SearchKeywordsRequest, GetAllKeywordsRequest, SetFavoriteBrandRequest } from "../transit/transit_service_pb.js";
import { Brand, Keyword } from "../types/brand_pb.js";

import "../style/Search.css";

interface Props {
    running: boolean;
    server?: TransitManagerClient;
}

interface State {
    counter: number;
    brands: Array<Brand>;
    knownKeywords: Array<Keyword>;
    keywords: Keyword[];
    searchValue: string;
    kwFuzzySearch: string;
}

class SearchManager extends React.Component<Props, State> {
    // eslint-disable-next-line no-useless-escape
    reg = new RegExp(/[\.,-\/#!$%\^&\*;:{}=\-_\`~()@\+\?><\[\]\+]/gi);

    constructor(props: Props) {
        super(props);
        this.doBrandSearch = this.doBrandSearch.bind(this);
        this.doKeywordSearch = this.doKeywordSearch.bind(this);
        this.getAllKeywords = this.getAllKeywords.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateSearch = this.updateSearch.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.toggleFavorite = this.toggleFavorite.bind(this);
    }

    static defaultProps: Props = {
        running: false,
    };

    state: Readonly<State> = {
        counter: 0,
        brands: new Array<Brand>(),
        knownKeywords: new Array<Keyword>(),
        keywords: [],
        searchValue: "",
        kwFuzzySearch: "",
    };

    componentDidMount(): void {
        this.getAllKeywords();
    }

    doBrandSearch(): void {
        const { counter, searchValue } = this.state;
        const { server } = this.props;
        if (!server || !searchValue) return;
        const req = new SearchBrandRequest();
        req.setReqId(counter);

        const words = searchValue.split(" ");
        console.group("Filter words");
        words.forEach((word) => {
            const kw = new Keyword();
            const w = word.toLowerCase().replaceAll(this.reg, "");
            //TODO see if we can match the keyword with anything we already have?
            console.log(w);
            kw.setKeywordValue(w);
            req.addKeywords(kw);
        });
        console.groupEnd();

        server.searchBrands(req, {}, (err, response) => {
            if (err || response === null) {
                console.log("error getting count", err);
                return;
            }
            this.setState({ brands: response.getBrandsList() });
        });

        this.incrementRequestCounter();
    }

    doKeywordSearch(): void {
        const { counter, kwFuzzySearch } = this.state;
        const { server } = this.props;
        if (!server) return;
        if (kwFuzzySearch.length < 3) return;

        const req = new SearchKeywordsRequest();
        req.setKeywordsList([kwFuzzySearch]);
        req.setReqId(counter);

        server.searchKeywords(req, {}, (err, response) => {
            if (err || response === null) {
                console.log("couldn't get keywords", err);
                return;
            }
            this.setState({ knownKeywords: response.getKeywordsList() });
        });

        this.incrementRequestCounter();
    }

    getAllKeywords(): void {
        const { counter } = this.state;
        const { server } = this.props;
        if (!server) return;

        const req = new GetAllKeywordsRequest();
        req.setReqId(counter);

        server.getAllKeywords(req, {}, (err, response) => {
            if (err || response === null) {
                console.log("couldn't get all keywords", err);
                return;
            }
            this.setState({ knownKeywords: response.getKeywordsList() });
        });
        this.incrementRequestCounter();
    }

    toggleFavorite(key: number): void {
        const {counter} = this.state;
        const {server} = this.props;
        const brand = this.state.brands[key];
        if(!server) return;

        const req = new SetFavoriteBrandRequest();
        req.setReqId(counter);
        req.setBrandId(brand.getUid());
        req.setTargetState(!brand.getIsFavorite());

        server.setFavoriteBrand(req, {}, (err, response) => {
            if(err || response === null) {
                console.log("Unable to favorite brand", err)
                return;
            }

            console.group("brand update")
            console.log("Sucessfully updated brand to:", response.getCurrentState())
            console.log(this.state.brands[key].getName(), this.state.brands[key].getIsFavorite())
            const updatedBrands = [...this.state.brands];
            updatedBrands[key].setIsFavorite(response.getCurrentState());
            this.setState({brands:updatedBrands}, ():void => {console.log(this.state.brands[key].getName(), this.state.brands[key].getIsFavorite()); console.groupEnd()});
        3})

        this.incrementRequestCounter();
    }

    incrementRequestCounter(): void {
        this.setState((state) => {
            return {
                counter: state.counter + 1,
            };
        });
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ kwFuzzySearch: event.target.value });
    }

    updateSearch(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ searchValue: event.target.value });
    }

    handleFormSubmit(e: React.KeyboardEvent<HTMLFormElement>): void {
        e.preventDefault();
        this.doBrandSearch();
    }

    render(): React.ReactNode {
        const { brands } = this.state;

        return (
            <div className="search-area">
                <Jumbotron>
                    <form onSubmit={this.handleFormSubmit}>
                        <div className="input-group mb-3 form-group">
                            <input
                                type="text"
                                value={this.state.searchValue}
                                onChange={this.updateSearch}
                                className="search-query input-xxlarge form-control"
                                placeholder="Try: hiking socks"
                                aria-label="Search brands here"
                            />
                            <Button className="btn btn-primary btn-secondary" type="submit">
                                <i className="fa fa-fw fa-lg fa-search" />
                            </Button>
                        </div>
                    </form>

                    <div>
                        {brands.length <= 0 ? (
                            <div>
                                <h3>Hello! Try searching for something you'd like to buy.</h3>
                                <p>We help you find things that are actually worth buying.</p>
                                <p>Sign in and add a brand if you don't see them here yet!</p>
                                <p>Note: search isn't <i>that</i> good, try to stick to nouns and general adjectives.</p>
                            </div>
                        ) : (
                            brands.map((brand, i) => {
                                return <Card key={i} id={i} brand={brand} toggleFavorite={this.toggleFavorite}/>;
                            })
                        )}
                    </div>
                </Jumbotron>
            </div>
        );
    }
}

export default SearchManager;
