import * as React from "react";

import { Brand } from "../types/brand_pb";
import FavoriteButton from "../components/FavoriteButton";
import ExternalLinkIcon from "../components/ExternalLinkIcon";

interface Props {
  brand: Brand;
  id: number;

  toggleFavorite: ((key: number) => void) | undefined;
}

interface State {
  clicked: boolean;
  showLink: boolean;
}

class Card extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.call = this.call.bind(this);
    this.showLinkIcon = this.showLinkIcon.bind(this);
    this.hideLinkIcon = this.hideLinkIcon.bind(this);
    this.toggleFavorite = this.toggleFavorite.bind(this);
  }

  static defaultBrand:Brand = new Brand();

  static defaultProps: Props = {
    brand:Card.defaultBrand,
    id: 0,
    toggleFavorite: undefined,
  };

  state: Readonly<State> = {
    clicked: false,
    showLink: false,
  };

  call(): void {
    console.log("Click");
  }

  showLinkIcon(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    this.setState({ showLink: true });
  }

  hideLinkIcon(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    this.setState({ showLink: false });
  }

  handleClick(e: React.MouseEvent): void {
    console.log("ahhhh clicked", this.props.brand?.getBrandUrl());
  }

  toggleFavorite(e: React.MouseEvent): void {
    if(!this.props.toggleFavorite) return;
    this.props.toggleFavorite(this.props.id)
  }

  render(): React.ReactNode {
    const {brand} = this.props;
    if (!brand) {
      return <></>;
    }

    return (
      <div>
        <div
          className="card"
          onMouseOver={this.showLinkIcon}
          onMouseOut={this.hideLinkIcon}
        >
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <a onClick={() => {
                window.location.assign(brand.getBrandUrl());
              }} href={brand.getBrandUrl()}>
                <div className="h5 card-title d-flex">
                  {brand.getName()}
                  {/* <ExternalLinkIcon show={this.state.showLink} /> */}
                </div>
              </a>
              <div className="float-right d-flex justify-content-end"><FavoriteButton toggleFavorite={this.toggleFavorite} liked={this.props.brand.getIsFavorite()} /></div>
            </div>
            <p className="card-text">{brand.getBrandDescription() || "has cool stuff"}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
