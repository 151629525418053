// source: types/brand.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.types.Brand', null, global);
goog.exportSymbol('proto.types.Keyword', null, global);
goog.exportSymbol('proto.types.ScrapeEvent', null, global);
goog.exportSymbol('proto.types.ScrapeEvent.scrape_state_enum', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Brand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.Brand.repeatedFields_, null);
};
goog.inherits(proto.types.Brand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Brand.displayName = 'proto.types.Brand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.Keyword = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.Keyword.repeatedFields_, null);
};
goog.inherits(proto.types.Keyword, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.Keyword.displayName = 'proto.types.Keyword';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.types.ScrapeEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.types.ScrapeEvent.repeatedFields_, null);
};
goog.inherits(proto.types.ScrapeEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.types.ScrapeEvent.displayName = 'proto.types.ScrapeEvent';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.Brand.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Brand.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Brand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Brand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Brand.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    brandUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    brandDescription: jspb.Message.getFieldWithDefault(msg, 6, ""),
    keywordsList: jspb.Message.toObjectList(msg.getKeywordsList(),
    proto.types.Keyword.toObject, includeInstance),
    ranking: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    dateAdded: jspb.Message.getFieldWithDefault(msg, 9, ""),
    addedBy: jspb.Message.getFieldWithDefault(msg, 10, ""),
    addedByEvent: jspb.Message.getFieldWithDefault(msg, 11, ""),
    isFavorite: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Brand}
 */
proto.types.Brand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Brand;
  return proto.types.Brand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Brand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Brand}
 */
proto.types.Brand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandDescription(value);
      break;
    case 7:
      var value = new proto.types.Keyword;
      reader.readMessage(value,proto.types.Keyword.deserializeBinaryFromReader);
      msg.addKeywords(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRanking(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateAdded(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddedBy(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddedByEvent(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavorite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Brand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Brand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Brand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Brand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBrandUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBrandDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.types.Keyword.serializeBinaryToWriter
    );
  }
  f = message.getRanking();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getDateAdded();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAddedBy();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAddedByEvent();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIsFavorite();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.types.Brand.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Brand} returns this
 */
proto.types.Brand.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.types.Brand.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Brand} returns this
 */
proto.types.Brand.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string brand_url = 4;
 * @return {string}
 */
proto.types.Brand.prototype.getBrandUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Brand} returns this
 */
proto.types.Brand.prototype.setBrandUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string image_url = 5;
 * @return {string}
 */
proto.types.Brand.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Brand} returns this
 */
proto.types.Brand.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string brand_description = 6;
 * @return {string}
 */
proto.types.Brand.prototype.getBrandDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Brand} returns this
 */
proto.types.Brand.prototype.setBrandDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated Keyword keywords = 7;
 * @return {!Array<!proto.types.Keyword>}
 */
proto.types.Brand.prototype.getKeywordsList = function() {
  return /** @type{!Array<!proto.types.Keyword>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.Keyword, 7));
};


/**
 * @param {!Array<!proto.types.Keyword>} value
 * @return {!proto.types.Brand} returns this
*/
proto.types.Brand.prototype.setKeywordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.types.Keyword=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Keyword}
 */
proto.types.Brand.prototype.addKeywords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.types.Keyword, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Brand} returns this
 */
proto.types.Brand.prototype.clearKeywordsList = function() {
  return this.setKeywordsList([]);
};


/**
 * optional float ranking = 8;
 * @return {number}
 */
proto.types.Brand.prototype.getRanking = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.Brand} returns this
 */
proto.types.Brand.prototype.setRanking = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string date_added = 9;
 * @return {string}
 */
proto.types.Brand.prototype.getDateAdded = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Brand} returns this
 */
proto.types.Brand.prototype.setDateAdded = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string added_by = 10;
 * @return {string}
 */
proto.types.Brand.prototype.getAddedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Brand} returns this
 */
proto.types.Brand.prototype.setAddedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string added_by_event = 11;
 * @return {string}
 */
proto.types.Brand.prototype.getAddedByEvent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Brand} returns this
 */
proto.types.Brand.prototype.setAddedByEvent = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool is_favorite = 12;
 * @return {boolean}
 */
proto.types.Brand.prototype.getIsFavorite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.types.Brand} returns this
 */
proto.types.Brand.prototype.setIsFavorite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.Keyword.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.Keyword.prototype.toObject = function(opt_includeInstance) {
  return proto.types.Keyword.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.Keyword} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Keyword.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    keywordValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    brandsList: jspb.Message.toObjectList(msg.getBrandsList(),
    proto.types.Brand.toObject, includeInstance),
    dateAdded: jspb.Message.getFieldWithDefault(msg, 4, ""),
    addedByEvent: jspb.Message.getFieldWithDefault(msg, 5, ""),
    ranking: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.Keyword}
 */
proto.types.Keyword.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.Keyword;
  return proto.types.Keyword.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.Keyword} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.Keyword}
 */
proto.types.Keyword.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeywordValue(value);
      break;
    case 3:
      var value = new proto.types.Brand;
      reader.readMessage(value,proto.types.Brand.deserializeBinaryFromReader);
      msg.addBrands(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateAdded(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddedByEvent(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRanking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.Keyword.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.Keyword.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.Keyword} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.Keyword.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKeywordValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBrandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.types.Brand.serializeBinaryToWriter
    );
  }
  f = message.getDateAdded();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddedByEvent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRanking();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.types.Keyword.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Keyword} returns this
 */
proto.types.Keyword.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string keyword_value = 2;
 * @return {string}
 */
proto.types.Keyword.prototype.getKeywordValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Keyword} returns this
 */
proto.types.Keyword.prototype.setKeywordValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Brand brands = 3;
 * @return {!Array<!proto.types.Brand>}
 */
proto.types.Keyword.prototype.getBrandsList = function() {
  return /** @type{!Array<!proto.types.Brand>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.types.Brand, 3));
};


/**
 * @param {!Array<!proto.types.Brand>} value
 * @return {!proto.types.Keyword} returns this
*/
proto.types.Keyword.prototype.setBrandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.types.Brand=} opt_value
 * @param {number=} opt_index
 * @return {!proto.types.Brand}
 */
proto.types.Keyword.prototype.addBrands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.types.Brand, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.Keyword} returns this
 */
proto.types.Keyword.prototype.clearBrandsList = function() {
  return this.setBrandsList([]);
};


/**
 * optional string date_added = 4;
 * @return {string}
 */
proto.types.Keyword.prototype.getDateAdded = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Keyword} returns this
 */
proto.types.Keyword.prototype.setDateAdded = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string added_by_event = 5;
 * @return {string}
 */
proto.types.Keyword.prototype.getAddedByEvent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.Keyword} returns this
 */
proto.types.Keyword.prototype.setAddedByEvent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float ranking = 6;
 * @return {number}
 */
proto.types.Keyword.prototype.getRanking = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.types.Keyword} returns this
 */
proto.types.Keyword.prototype.setRanking = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.types.ScrapeEvent.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.types.ScrapeEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.types.ScrapeEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.types.ScrapeEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.ScrapeEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    scrapeState: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scrapeBrand: (f = msg.getScrapeBrand()) && proto.types.Brand.toObject(includeInstance, f),
    scrapeUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    scrapeUrlCanonical: jspb.Message.getFieldWithDefault(msg, 5, ""),
    scrapeStartTime: jspb.Message.getFieldWithDefault(msg, 6, ""),
    scrapeEndTime: jspb.Message.getFieldWithDefault(msg, 7, ""),
    scrapeContent: jspb.Message.getFieldWithDefault(msg, 8, ""),
    scrapeContentType: jspb.Message.getFieldWithDefault(msg, 9, ""),
    scrapeMetaDescription: jspb.Message.getFieldWithDefault(msg, 10, ""),
    scrapeMetaName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    scrapeMetaImgUrl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    scrapeRelAltHref: jspb.Message.getFieldWithDefault(msg, 13, ""),
    scrapeRelAltType: jspb.Message.getFieldWithDefault(msg, 14, ""),
    scrapeDiscardedKeywordsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    scrapeScheduledTime: jspb.Message.getFieldWithDefault(msg, 16, ""),
    dateAdded: jspb.Message.getFieldWithDefault(msg, 17, ""),
    addedByUser: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.types.ScrapeEvent}
 */
proto.types.ScrapeEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.types.ScrapeEvent;
  return proto.types.ScrapeEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.types.ScrapeEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.types.ScrapeEvent}
 */
proto.types.ScrapeEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScrapeState(value);
      break;
    case 3:
      var value = new proto.types.Brand;
      reader.readMessage(value,proto.types.Brand.deserializeBinaryFromReader);
      msg.setScrapeBrand(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScrapeUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setScrapeUrlCanonical(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setScrapeStartTime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setScrapeEndTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setScrapeContent(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setScrapeContentType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setScrapeMetaDescription(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setScrapeMetaName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setScrapeMetaImgUrl(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setScrapeRelAltHref(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setScrapeRelAltType(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addScrapeDiscardedKeywords(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setScrapeScheduledTime(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateAdded(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddedByUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.types.ScrapeEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.types.ScrapeEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.types.ScrapeEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.types.ScrapeEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScrapeState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScrapeBrand();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.types.Brand.serializeBinaryToWriter
    );
  }
  f = message.getScrapeUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScrapeUrlCanonical();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScrapeStartTime();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getScrapeEndTime();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getScrapeContent();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getScrapeContentType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getScrapeMetaDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getScrapeMetaName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getScrapeMetaImgUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getScrapeRelAltHref();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getScrapeRelAltType();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getScrapeDiscardedKeywordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
  f = message.getScrapeScheduledTime();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getDateAdded();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getAddedByUser();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.types.ScrapeEvent.scrape_state_enum = {
  SCRAPE_INVALID: 0,
  SCRAPE_WAITING: 1,
  SCRAPE_STARTED: 2,
  SCRAPE_COMPLETE: 3,
  SCRAPE_FAIL: 4,
  SCRAPE_REMOTE_FAIL: 5
};

/**
 * optional string uid = 1;
 * @return {string}
 */
proto.types.ScrapeEvent.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string scrape_state = 2;
 * @return {string}
 */
proto.types.ScrapeEvent.prototype.getScrapeState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.setScrapeState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Brand scrape_brand = 3;
 * @return {?proto.types.Brand}
 */
proto.types.ScrapeEvent.prototype.getScrapeBrand = function() {
  return /** @type{?proto.types.Brand} */ (
    jspb.Message.getWrapperField(this, proto.types.Brand, 3));
};


/**
 * @param {?proto.types.Brand|undefined} value
 * @return {!proto.types.ScrapeEvent} returns this
*/
proto.types.ScrapeEvent.prototype.setScrapeBrand = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.clearScrapeBrand = function() {
  return this.setScrapeBrand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.types.ScrapeEvent.prototype.hasScrapeBrand = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string scrape_url = 4;
 * @return {string}
 */
proto.types.ScrapeEvent.prototype.getScrapeUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.setScrapeUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string scrape_url_canonical = 5;
 * @return {string}
 */
proto.types.ScrapeEvent.prototype.getScrapeUrlCanonical = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.setScrapeUrlCanonical = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string scrape_start_time = 6;
 * @return {string}
 */
proto.types.ScrapeEvent.prototype.getScrapeStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.setScrapeStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string scrape_end_time = 7;
 * @return {string}
 */
proto.types.ScrapeEvent.prototype.getScrapeEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.setScrapeEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string scrape_content = 8;
 * @return {string}
 */
proto.types.ScrapeEvent.prototype.getScrapeContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.setScrapeContent = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string scrape_content_type = 9;
 * @return {string}
 */
proto.types.ScrapeEvent.prototype.getScrapeContentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.setScrapeContentType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string scrape_meta_description = 10;
 * @return {string}
 */
proto.types.ScrapeEvent.prototype.getScrapeMetaDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.setScrapeMetaDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string scrape_meta_name = 11;
 * @return {string}
 */
proto.types.ScrapeEvent.prototype.getScrapeMetaName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.setScrapeMetaName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string scrape_meta_img_url = 12;
 * @return {string}
 */
proto.types.ScrapeEvent.prototype.getScrapeMetaImgUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.setScrapeMetaImgUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string scrape_rel_alt_href = 13;
 * @return {string}
 */
proto.types.ScrapeEvent.prototype.getScrapeRelAltHref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.setScrapeRelAltHref = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string scrape_rel_alt_type = 14;
 * @return {string}
 */
proto.types.ScrapeEvent.prototype.getScrapeRelAltType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.setScrapeRelAltType = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated string scrape_discarded_keywords = 15;
 * @return {!Array<string>}
 */
proto.types.ScrapeEvent.prototype.getScrapeDiscardedKeywordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.setScrapeDiscardedKeywordsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.addScrapeDiscardedKeywords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.clearScrapeDiscardedKeywordsList = function() {
  return this.setScrapeDiscardedKeywordsList([]);
};


/**
 * optional string scrape_scheduled_time = 16;
 * @return {string}
 */
proto.types.ScrapeEvent.prototype.getScrapeScheduledTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.setScrapeScheduledTime = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string date_added = 17;
 * @return {string}
 */
proto.types.ScrapeEvent.prototype.getDateAdded = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.setDateAdded = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string added_by_user = 18;
 * @return {string}
 */
proto.types.ScrapeEvent.prototype.getAddedByUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.types.ScrapeEvent} returns this
 */
proto.types.ScrapeEvent.prototype.setAddedByUser = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


goog.object.extend(exports, proto.types);
