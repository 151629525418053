import React, { ReactElement } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Form, Button, Jumbotron } from "react-bootstrap";
import { GoogleLoginButton, FacebookLoginButton } from "react-social-login-buttons";
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
import { TransitManagerClient } from "../transit/Transit_serviceServiceClientPb";
import FacebookLogin, {FacebookLoginRenderProps} from "react-facebook-login/dist/facebook-login-render-props";
import { withRouter } from 'react-router-dom';

interface LoginPageProps {
  server?: TransitManagerClient;
}

interface LoginPageState {
  email: string;
  password: string;
}

class LoginPage extends React.Component<LoginPageProps & RouteComponentProps, LoginPageState> {
  constructor(props: LoginPageProps & RouteComponentProps) {
    super(props);
  }

  static defaultProps: LoginPageProps = {
    server: undefined
  };

  state: Readonly<LoginPageState> = {
    email: "",
    password: "",
  };

  responseGoogle(e: GoogleLoginResponse | GoogleLoginResponseOffline): void {
    console.log(e);
    e.code;
  }

  redirectToHome = ():void => {
    const { history } = this.props;
    if(history) history.push('/');
   }

  render(): ReactElement {
    return (
      <div className="form-container">
        <div className="form-area">
          <Jumbotron>
            <h3>Login with social:</h3>
            <br></br>

<GoogleLoginButton onClick={()=> window.location = "https://api.brands.place:8812/login/google"}/>
            {/* <FacebookLogin appId="785260588996852"  fields="name,email,picture" scope="public_profile,email" callback={console.log} render={(renderProps: FacebookLoginRenderProps) => <FacebookLoginButton onClick={renderProps.onClick} />} /> */}

            <hr />
            <p>More login options coming soon!</p>
            {/* Put SSO options here. */}
            {/* Pretty bar with ----- OR ----- in the middle */}
            {/* <Form hidden>
              <Form.Group className="form-label-group">
                <Form.Label htmlFor="inputEmail">Email </Form.Label>
                <Form.Control id="inputEmail" className="form-control" type="email" placeholder="Enter email" />
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label htmlFor="inputPassword">Password</Form.Label>
                <Form.Control id="inputPassword" type="password" placeholder="Password" />
              </Form.Group>
              <Button className="btn-block" variant="primary" type="submit">
                Login
              </Button>
            </Form> */}
          </Jumbotron>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginPage);
