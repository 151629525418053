import React, { ReactElement } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Form, Button, Jumbotron } from "react-bootstrap";
import { GoogleLoginButton, FacebookLoginButton } from "react-social-login-buttons";
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
import { TransitManagerClient } from "../transit/Transit_serviceServiceClientPb";
import FacebookLogin, {FacebookLoginRenderProps} from "react-facebook-login/dist/facebook-login-render-props";
import { withRouter } from 'react-router-dom';
import { InsertBrandRequest } from "../transit/transit_service_pb";
import { Brand } from "../types/brand_pb.js";

interface AddBrandPageProps {
  server?: TransitManagerClient;
}

interface AddBrandPageState {
  url: string;
  success?: boolean;
  submitting?: boolean;
}

class AddBrandPage extends React.Component<AddBrandPageProps & RouteComponentProps, AddBrandPageState> {
  constructor(props: AddBrandPageProps & RouteComponentProps) {
    super(props);
    this.updateUrl = this.updateUrl.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.doInsertBrand = this.doInsertBrand.bind(this);
  }

  static defaultProps: AddBrandPageProps = {
    server: undefined
  };

  state: Readonly<AddBrandPageState> = {
    url: "",
    success: undefined,
    submitting: false,
  };

  redirectToHome = ():void => {
    const { history } = this.props;
    if(history) history.push('/');
   }

   updateUrl(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ url: event.target.value, success: undefined });
   }

   
   handleFormSubmit(e: React.KeyboardEvent<HTMLFormElement>): void {
    e.preventDefault();
    this.doInsertBrand();
   }

   doInsertBrand(): void {
     this.setState({submitting:true});
    const { url } = this.state;
    const { server } = this.props;
    if (!server) return;
    const req = new InsertBrandRequest();
    const brand = new Brand();
    brand.setBrandUrl(url);
    req.setReqId(0);
    req.setBrand(brand);

    //try to sanitize urls here?

    server.insertBrand(req, {}, (err, response) => {
        if (err || response === null) {
            console.log("error requesting brand review", err);
            this.setState({ success: false, submitting: false });
            return;
        }
        this.setState({ success: true, submitting:false});
    });
   }


  render(): ReactElement {
    return (
      <div className="form-container">
        <div className="form-area">
          <Jumbotron>
            <p>Suggest a brand to be added.</p>
            <p>Sugestions are typically added within one minute.</p> <p>Please only suggest brands that you like. The goal of this site is to surface new, unique, and interesting brands. If you requested a brand, but can't find it or have any questions, send me an email.</p>
            <form onSubmit={this.handleFormSubmit}>
                        <div className="input-group mb-3 form-group">
                            <input
                                type="text"
                                value={this.state.url}
                                onChange={this.updateUrl}
                                className="search-query input-xxlarge form-control"
                                placeholder="http://neatbrand.com"
                                aria-label="Suggest a brand"
                            />
                            <Button className={"btn btn-primary " + (this.state.success != undefined? (this.state.success? "btn-success" : "btn-danger") : "btn-secondary")} type="submit" disabled={this.state.submitting}>
                                <i className="fa fa-fw fa-lg fa-check" />
                            </Button>
                        </div>
                    </form>
                    <p>Make sure you're logged in first!</p>
          </Jumbotron>
        </div>
      </div>
    );
  }
}

export default withRouter(AddBrandPage);
