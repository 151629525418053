import React, { FunctionComponent, useState } from "react";
import { Toggle } from "react-bootstrap/lib/Panel";

const FavoriteButton: FunctionComponent<{ liked: boolean , toggleFavorite: (e:React.MouseEvent) => void | undefined}> = ({ liked = false, toggleFavorite = undefined }) => {
  const [mouseOn, setMouseOn] = useState(false);
  // const [active, setActive] = useState(liked);
  let mainClass = "fa-heart-o";
  if (mouseOn || liked) {
    mainClass = "fa-heart";
  }

const heartColor= (liked? "red" : "black");

  return (
    <div
      style={{
        color: heartColor,
      }}
      className="float-right"
      onMouseOver={() => {
        setMouseOn(true);
        console.log("on");
      }}
      onMouseOut={() => {
        setMouseOn(false);
      }}
      onClick={(e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
        e.preventDefault();
        if(toggleFavorite) {
          toggleFavorite(e);
        }
      }}
    >
      <i className={"fa fa-fw " + mainClass} />
    </div>
  );
};

export default FavoriteButton;
